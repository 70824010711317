// header heights
$header-height-mobile: 80px; // this is a calculated value by hand
$header-height-tablet: 128px; // this is a calculated value by hand

// body outer spacings
$body-outer-spacing-mobile: 0.625rem;
$body-outer-spacing-desktop: 30px;

// containers
$container-xs-width: 355px;
$container-sm-width: 450px;
$container-md-width: 600px;

// spacings
$spacing-sm: 10px;
$spacing-md: 20px;
$spacing-lg: 40px;

// z-indexes
$z-index-header: 3;
$z-index-content: 1;
$z-index-footer: 1;
$z-index-rings: 0;
$z-index-overlay: 99999;

// breakpoints
$breakpoint-product-selection: 800px;

//dimensions
$button-standard-width: 288px;
