@use '@plus/ds-tokens/files/generated/tokens/mixins.scss' as dsMixins;
@use './src/app/styles/variables' as ffcVars;

@import 'ngx-toastr/toastr';

// overwrite toast colors
.toast-success {
  background-color: var(--colors-semantic-success-normal-contrast);
}

.toast-error {
  background-color: var(--colors-semantic-error-normal-contrast);
}

.toast-decent {
  background-color: var(--colors-ui-highlight);

  .toast-close-button,
  div {
    color: var(--colors-background);
  }
}

.toast-container {
  margin-top: ffcVars.$header-height-mobile; // Header height

  @include dsMixins.breakpoints_md {
    margin-top: ffcVars.$header-height-tablet;
  }
}

.toast-container .ngx-toastr {
  box-shadow: none;
  display: block;

  &:hover,
  &:focus {
    box-shadow: none;
  }
}
