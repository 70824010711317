@use '@plus/ds-tokens/files/generated/tokens/mixins' as dsMixins;
@use 'src/app/styles/variables' as ffcVars;

@mixin primary-button {
  color: var(--colors-on-ui-highlight-high-emphasis);
  background: var(--colors-ui-highlight);
  border: none;
  font-size: 16px;
  line-height: 1;
  padding: 12px;
  width: 100%;

  &:hover,
  &:focus {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--colors-on-ui-low-emphasis);
    background-color: var(--colors-ui-medium-contrast);
  }

  box-sizing: border-box;
  display: block;
  text-align: center;
  text-decoration: none;
}

@mixin container {
  display: block;
  position: relative;
  z-index: ffcVars.$z-index-content;
  margin-left: auto;
  margin-right: auto;
  padding-left: ffcVars.$spacing-sm;
  padding-right: ffcVars.$spacing-sm;
  max-width: 725px;
}

@mixin breakpoint-custom-down($max-width) {
  @media screen and (max-width: $max-width - 1) {
    @content;
  }
}

@mixin breakpoint-custom-up($min-width) {
  @media screen and (min-width: $min-width) {
    @content;
  }
}

@mixin dialog {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: var(--colors-background-variant);
  box-sizing: border-box;
  font: var(--typography-interface-caption);

  @include dsMixins.breakpoints_xxs {
    width: 288px;
    max-height: 560px;
    padding: var(--sizes-24) var(--sizes-16);
  }

  @include dsMixins.breakpoints_sm {
    width: 360px;
  }

  @include dsMixins.breakpoints_md {
    width: 652px;
    max-height: none;
    padding: var(--sizes-24);
  }

  @include dsMixins.breakpoints_lg {
    width: 806px;
    padding: var(--sizes-32);
    font: var(--typography-interface-body);
  }

  .heading {
    margin-bottom: var(--sizes-12);
  }

  .title {
    margin: 0;
    font: var(--typography-interface-title3-bold);

    @include dsMixins.breakpoints_lg {
      font: var(--typography-interface-title2-bold);
    }
  }

  .subtitle {
    margin: 0;
    font: var(--typography-interface-caption-bold);
    color: var(--colors-on-background-medium-emphasis);

    @include dsMixins.breakpoints_lg {
      font: var(--typography-interface-body-bold);
    }
  }

  .content {
    overflow-y: auto;
  }

  .actions {
    display: flex;
    gap: var(--sizes-12);
    flex-direction: column;
    margin: var(--sizes-24) 0 0;

    @include dsMixins.breakpoints_md {
      flex-direction: row-reverse;
    }
  }
}

@mixin rounded-corners-sm(){
  border-radius: var(--radii-sm);
}

@mixin rounded-corners-md(){
  border-radius: var(--radii-md);
}
